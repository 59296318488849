.app {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 100%;
}

.primary {
  font-size: 4em;
}

.secondary {
  font-size: 1em;
  color: gray;
}

.tertiary {
  font-size: 0.8em;
  color: white;
  opacity: 0.5;
}

.durations {
  font-size: 0.8em;
  color: white;
}

.dur {
  padding-top: 20px;
  font-size: 0.8em;
}

.select {
  display: inline-grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;
  width: 100%;
  min-width: 6ch;
  max-width: 7ch;
  border: 1px solid #777;
  border-radius: 0.25em;
  padding: 0.25em 0.5em;
  margin: 0 0.5em;
  font-size: 0.8em;
  cursor: pointer;
  line-height: 1.1;
  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
}

select,
.select:after {
  grid-area: select;
}

.select::after {
  content: "";
  width: 0.8em;
  height: 0.5em;
  background-color: #777AAA;
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  justify-self: end;
}

select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
}

select:focus + .focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid blue;
  border-radius: inherit;
}